import React, { Component } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import { injectIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"

import Layout from "../containers/Layout"
import SEO from "../components/seo"
import axios from "../api/axios"
import NoImg from "../images/no_img.png"
import * as actions from "../store/actions"


class LinksPage extends Component {

  state = {
    allLinks: [],
    filteredLinks: [],
    activeOwnerTypeValue: "all_links",
    isLoading: true,
  }

  componentDidMount() {
    var ownerUrlParam = new URL(window.location.href).searchParams.get("owner") || "all_links";
    this.setState({ activeOwnerTypeValue: ownerUrlParam })
    if (this.props.selectedRegion !== null) {
      this.fetchLinks(this.props.selectedRegion.id)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedRegion !== prevProps.selectedRegion) {
      var ownerUrlParam = new URL(window.location.href).searchParams.get("owner") || "all_links";
      this.setState({ activeOwnerTypeValue: ownerUrlParam })
      this.fetchLinks(this.props.selectedRegion.id)
    }
  }

  fetchLinks = (regionID) => {
    this.setState({ isLoading: true })
    if (this.props.selectedRegion.code === "GCC") {
      axios(this.props.intl.locale, `links/`)
        .then(response => {
          const regionLinks = []
          for (let i = 0; i < response.data.length; i++) {
            regionLinks.push({
              id: response.data[i].id,
              title: response.data[i].title,
              image: response.data[i].image,
              url: response.data[i].url,
              owner_type: response.data[i].owner_type,
            })
          }
          this.setState({ allLinks: regionLinks, isLoading: false }, () => {
            this.filterLinks(this.state.activeOwnerTypeValue)
          })
        })
    } else {
      axios(this.props.intl.locale, `links/?region=${regionID}`)
        .then(response => {
          const regionLinks = []
          for (let i = 0; i < response.data.length; i++) {
            regionLinks.push({
              id: response.data[i].id,
              title: response.data[i].title,
              image: response.data[i].image,
              url: response.data[i].url,
              owner_type: response.data[i].owner_type,
            })
          }
          this.setState({ allLinks: regionLinks, isLoading: false }, () => {
            this.filterLinks(this.state.activeOwnerTypeValue)
          })
        })
    }
    navigate("/links/?country=" + this.props.selectedRegion.code + "&owner=" + this.state.activeOwnerTypeValue)
  }

  filterLinks = (ownerTypeValue) => {
    let filteredLinks = []
    if (ownerTypeValue === "all_links") {
      filteredLinks = this.state.allLinks
    } else {
      filteredLinks = this.state.allLinks
        .filter((item) => item.owner_type === ownerTypeValue)
    }
    this.setState({
      filteredLinks: filteredLinks,
      activeOwnerTypeValue: ownerTypeValue
    })
    navigate("/links/?country=" + this.props.selectedRegion.code + "&owner=" + ownerTypeValue)
  }

  render() {
    const linksCategories = [
      { value: "all_links", name: "all_links" },
      { value: "Government", name: "government" },
      { value: "NGO", name: "non_government" },
      { value: "ResearchInstitute", name: "research_institute" },
      { value: "Private", name: "private" }
    ]

    const activeFilterClass = "btn btn-outline-default"
    const nonActiveFilterClass = "btn disabled btn-outline-default"
    const noLinksMessage = <h3><FormattedMessage id="no_links_to_display" /></h3>
    return (
      <Layout>
        <SEO title="Links" />
        <div className="main">
          <div className="section text-center">
            <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }}>
              <Container>
                <Row>
                  <h4 className="mt-1"><FormattedMessage id="links_app" /></h4>
                </Row>
              </Container>
            </div>
            <br />
            <Container>
              <div className="links-wrapper">
                {linksCategories.map((link) => {
                  return (
                    <div className="links-item-wrapper" key={"links_page" + link.name}>
                      <button onClick={() => { this.filterLinks(link.value) }}
                        className={this.state.activeOwnerTypeValue === link.value ? activeFilterClass : nonActiveFilterClass}>
                        <FormattedMessage id={link.name} />
                      </button>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                  )
                })}
              </div>
              <br />
              {this.state.isLoading ? <Spinner /> : null}
              {this.state.isLoading === false && this.state.filteredLinks.length === 0 ? noLinksMessage : null}
              <Row className=" justify-content-center">
                {this.state.filteredLinks.map((item, i) =>
                  <Col lg={4} md={6} sm={6} key={i}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <Card className="links-card">
                        <img className="photo" src={item.image || NoImg} alt="link-img" />
                        <CardBody className="links-body">
                          <h5 className="links-text">{item.title}</h5>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>)}
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    regions: state.regions.regions,
    selectedRegion: state.regions.selectedRegion,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRegion: regionCode => dispatch(actions.setSelectedRegion(regionCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LinksPage))
